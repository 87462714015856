<template>
  <div class="flex h-screen bg-white flex-col lg:flex-row">
    <div class="w-full lg:w-1/2 relative flex justify-center items-center">
      <div class="logo">
        <el-image src="/images/home/logo.svg" alt="" />
      </div>
    </div>

    <div class="w-full lg:w-1/2 bg-[#2A459C] flex justify-center items-center">
      <div class="p-5 text-white w-[500px] h-[500px] m-auto">
        <h1 class="mb-10">{{ $t('Đăng nhập') }}</h1>

        <el-form ref="formRef" :model="form" :rules="rules" label-position="top" class="formLoginUser">
          <el-form-item
            :label="$t('Email đăng nhập')"
            prop="email"
            :inline-message="hasError('email')"
            :error="getError('email')"
            class="input-el-login"
          >
            <el-input v-model="form.email" size="large" class="w-360px" />
          </el-form-item>
          <el-form-item
            :label="$t('Mật khẩu')"
            prop="password"
            :inline-message="hasError('password')"
            :error="getError('password')"
            class="input-el-login"
          >
            <el-input v-model="form.password" size="large" type="password" show-password />
          </el-form-item>
        </el-form>

        <el-button class="w-full" type="warning" @click.prevent="handleLogin">{{ $t('Login') }}</el-button>
        <!-- <div class="mt-5">
          <a @click="resendVerifyEmail" class="text-white">Lấy lại đường link xác thực email</a>
        </div> -->
        <div class="mt-2 flex justify-between max-sm:flex-col">
          <div>
            <span class="style-text text-[15px] font-normal"
              >Bạn chưa có tài khoản?
              <span class="text-[#F49D2E] cursor-pointer text-[14px] font-normal" @click="goToRegisterPage"
                >Đăng ký</span
              ></span
            >
          </div>
          <div @click="goToResetPasswordPage">
            <span class="style-text text-[15px] font-normal cursor-pointer">Bạn quên mật khẩu? </span>
          </div>
        </div>
        <div class="my-7 flex items-center">
          <span class="style-text text-[15px] font-normal">Đăng nhập tài khoản với</span>
          <div class="ml-8 flex">
            <a href="/social/facebook"><img class="mr-4 w-[40px] h-[40px]" src="images/user/facebook.png" /></a>
            <a href="/social/google"><img class="w-[40px] h-[40px]" src="images/user/google.png" /></a>
          </div>
        </div>
        <div class="flex items-center mt-[23px] cursor-pointer" @click.prevent="goToPrevPage">
          <img class="w-4 h-4 mr-1" src="images/user/arrow-right-circle.png" />
          <span class="style-text text-[15px] font-normal">Quay lại</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      form: this.$inertia.form({
        email: null,
        password: null,
        remember: false,
      }),
      rules: {
        email: [{ required: true, message: 'Trường tên tài khoản không được bỏ trống.', trigger: 'blur' }],
        password: [{ required: true, message: 'Trường mật khẩu không được bỏ trống.', trigger: 'blur' }],
      },
      loading: false,
    }
  },
  watch: {
    //
  },
  created() {
    //
  },
  methods: {
    async resendVerifyEmail() {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('verification.verify'))
    },
    goToRegisterPage() {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('register'))
    },
    goToResetPasswordPage() {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('resetPasswordScreen'))
    },
    handleLogin() {
      this.form.errors = {}
      this.$refs['formRef'].validate(async (valid) => {
        if (!valid) return

        this.form.post('/login', {
          preserveScroll: true,
          onSuccess: () => {
            // eslint-disable-next-line no-undef
            this.$inertia.visit(route('home'))
          },
        })
      })
    },
    getError(field) {
      return this.hasError(field) ? this.form.errors[field] : ''
    },
    hasError(field) {
      return !!(this.form.errors && this.form.errors[field])
    },
    goToPrevPage() {
      history.go(-1)
    },
  },
}
</script>
<style>
.formLoginUser .el-form-item__label {
  color: #ffffff !important;
}
.formLoginUser .input-el-login .el-input__wrapper {
  background: rgba(32, 120, 206, 0.5);
  height: 40px;
}
.formLoginUser .input-el-login .el-input__wrapper .el-input__inner {
  color: #e1e1e1;
}
</style>
